<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t( translationPath + ( currentUser.strict_accounting_mode ? 'title_expense_accounts' : 'title_expense_categories')) }}</h1>
      </b-row>
      <account-plan-submenu active="expenses"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-expenses-header></account-plan-expenses-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <ExpenseMenu>
              <template slot="account-plan-expenses-misc">
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'expense.misc.description')"></div>
                  </b-col>
                </b-row>
                <b-table class="spirecta-simple-table account-table-misc-expenses-settings income-accounts-table" show-empty
                         hover responsive striped
                         stacked="md"
                         ref="TableMisc"
                         :items="accounts"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                         :sort-compare="sortCompare"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:cell(group)="row">
                    <custom-select
                      :disabled="isFormDisabled"
                      v-model="row.item.group"
                      :options="accountGroups.expense"
                      label="title"
                      :placeholder="'Select account group'"
                      :search-placeholder="$t('common.type_to_filter').toString()"
                      @select="hasUnsavedChanges = true"
                    ></custom-select>
                  </template>

                  <template v-slot:cell(code)="row">
                    <b-form-input
                      class="input-code"
                      :class="row.item.is_invalid_code ? 'is-invalid' : ''"
                      v-model="row.item.code"
                      @input="(value) => onCodeInput(row.item, value)"
                    ></b-form-input>
                  </template>

                  <template v-slot:cell(title)="row">
                    <b-form-input
                      v-model="row.item.title"
                      :class="row.item.is_invalid_title ? 'is-invalid' : ''"
                      @input="(value) => onTitleInput(row.item, value)"
                    ></b-form-input>
                  </template>

                  <template v-slot:cell(default_active_percentage)="row">
                    <percentage-input
                      v-model="row.item.default_active_percentage"
                      :value="row.value"
                      :inputId="'account_' + row.item.id + '_active_percentage'"
                      inputClass="input-active-percentage"
                      @input="hasUnsavedChanges = true"
                    ></percentage-input>
                  </template>

                  <template v-slot:cell(actions)="row">
                    <button
                      class="btn plain-btn text-regular action-button"
                      @click="onDeleteAccount(row.item)"
                    >
                      <i class="flaticon solid trash-3 text-secondary" :title="$t('common.delete')"></i>
                    </button>
                  </template>
                </b-table>
                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled || busy"
                      @click="onSave"
                    >{{ $t('common.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </ExpenseMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>

    <delete-networth-account-modal ref="deleteModalNetworth"></delete-networth-account-modal>

    <delete-account-modal
      ref="deleteModal"
      :itemName="deleteAccountName"
      :itemType="deleteAccountType"
      :moveTransactions="onDeleteMoveTransactions"
      :iDestroyAccountId="iDestroyAccountId"
      @on-delete-confirm="deleteAccount"
      @hide="resetModal"
    ></delete-account-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import CustomSelect from '@/components/common/CustomSelect'
import _ from 'lodash'
import PercentageInput from '@/components/common/PercentageInput'
import ExpenseMenu from './submenu/ExpenseMenu'
import AccountPlanExpensesHeader from './headers/AccountPlanExpensesHeader'

export default {
  name: 'AccountPlanExpensesMisc',
  components: { CustomSelect, PercentageInput, ExpenseMenu, AccountPlanExpensesHeader },
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  data () {
    return {
      accounts: []
    }
  },
  computed: {
    local () {
      return this.$i18n.locale
    },
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), sortable: true, class: 'td-title' },
        { key: 'code', label: this.$t(this.translationPath + 'table.th_code'), sortable: true, class: 'td-code' },
        { key: 'group', label: this.$t(this.translationPath + 'table.th_account_group'), sortable: true, class: 'td-account_group' },
        { key: 'default_active_percentage', label: this.$t(this.translationPath + 'table.th_active_percentage'), sortable: true, class: 'td-percentage' },
        { key: 'actions', label: '', class: 'td-actions' }
      ]
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('expense'),
        this.loadAccountGroups('expense')
      ])
        .then(() => {
          this.makeRows()
          this.busy = false
        })
    },
    makeRows () {
      if (this.accountsGrouped.expense) {
        this.accounts = _.cloneDeep(this.accountsGrouped.expense).filter((item) => item.is_account).sort((a, b) => {
          const nameA = a.title
          const nameB = b.title
          if (this.locale) {
            return nameA.localeCompare(nameB, this.locale)
          } else {
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
            return 0
          }
        })
        this.accounts.map((account) => {
          account.is_invalid_title = false
          account.is_invalid_code = false
          account.group = null
          if (this.accountGroups.expense) {
            this.accountGroups.expense.map((group) => {
              if (account.group_id === group.id) {
                account.group = group
              }
            })
          }
        })
      }
    },
    onSave () {
      this.saveDisabled = true

      const putData = {
        accounts: []
      }
      this.accounts.map((item) => {
        putData.accounts.push({
          id: item.id,
          group_id: item.group.id,
          default_active_percentage: item.default_active_percentage,
          title: item.title,
          code: item.code
        })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_misc_success_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_misc_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.accounts.map((item) => {
            item.is_invalid_title = false
            item.is_invalid_code = false
          })
          this.$refs.TableMisc.refresh()
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_misc_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_misc_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })

          const response = error.response.data
          if (response.title) {
            response.title.map((id) => {
              this.accounts.map((item) => {
                if (item.id === id) {
                  item.is_invalid_title = true
                }
              })
            })
          }
          if (response.code) {
            response.code.map((id) => {
              this.accounts.map((item) => {
                if (item.id === id) {
                  item.is_invalid_code = true
                }
              })
            })
          }
          this.$refs.TableMisc.refresh()
        })
        .then(() => {
          this.saveDisabled = false
        })
    },
    onTitleInput (item, value) {
      item.is_invalid_title = false
      if (value.length < 2) {
        item.is_invalid_title = true
      }
      this.hasUnsavedChanges = true
    },
    onCodeInput (item, value) {
      item.is_invalid_code = !(new RegExp(/^\d*$/)).test(value)
      this.hasUnsavedChanges = true
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    accountGroups: {
      deep: true,
      handler () {
        if (!this.accountGroups.expense) {
          return false
        }

        this.accounts.map((account) => {
          this.accountGroups.expense.map((group) => {
            if (account.group_id === group.id) {
              account.group = group
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
